<template>
  <b-modal
    id="prompt-filter-modal"
    ref="prompt-filter-modal"
    title="Prompt Filter"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <HeroVueSelect
      id="team-name"
      v-model="filter.teamName"
      label="Team Name"
      :options="teamName.options"
      :loading="teamName.isLoading"
    />
    <HeroVueSelectMultiple
      id="products"
      v-model="filter.products"
      label="Products"
      :options="product.options"
      :loading="product.isLoading"
    />
    <HeroVueSelect
      id="platform"
      v-model="filter.platform"
      label="Platform"
      :options="platform.options"
      :loading="platform.isLoading"
    />
    <hr>
    <b-row>
      <b-col cols="6">
        <HeroButtonAction
          type="button"
          variant="primary"
          @click="resetFilter"
        >
          Clear
        </HeroButtonAction>
      </b-col>
      <b-col cols="6">
        <HeroButtonAction
          type="button"
          variant="primary"
          @click="onSearch"
        >
          Search
        </HeroButtonAction>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'

export default {
  components: {
    HeroVueSelectMultiple,
    HeroVueSelect,
    HeroButtonAction,
  },
  props: {
    product: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
    teamName: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
    platform: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
  },
  data() {
    return {
      showOverlay: false,
      filter: {
        products: [],
        teamName: '',
        platform: '',
      },
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.filter)
      this.$bvModal.hide('prompt-filter-modal')
    },

    resetFilter() {
      this.filter.products = []
      this.filter.teamName = ''
      this.filter.platform = ''
    },
  },
}
</script>
