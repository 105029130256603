export default {
  data() {
    return {
      debounceTimer: null,
    }
  },
  methods: {
    debounce(cb, timeout = 300) {
      return (...args) => {
        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => { cb.apply(this, args) }, timeout)
      }
    },
  },
}
