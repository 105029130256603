<template>
  <b-modal
    id="prompt-form-modal"
    ref="prompt-form-modal"
    :title="promptId ? 'Edit' : 'Create'"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="onShown"
    @hidden="resetForm"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.prevent="handleSubmit">
        <validation-provider
          #default="{ errors }"
          name="name"
          rules="required"
        >
          <HeroInputText
            id="name"
            v-model="form.name"
            label="Task"
            required
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="team name"
          rules="required"
        >
          <HeroVueSelect
            id="teamName"
            v-model="form.teamName"
            label="Team Name"
            required
            :options="teamName.options"
            :loading="teamName.isLoading"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
        <HeroVueSelectMultiple
          id="products"
          v-model="form.productIds"
          label="Products"
          :options="product.options"
          :loading="product.isLoading"
        />
        <HeroVueSelect
          id="platform"
          v-model="form.platform"
          label="Platform"
          :options="platform.options"
          :loading="platform.isLoading"
        />
        <validation-provider
          v-if="form.platform === 'Other'"
          #default="{ errors }"
          name="other platform"
          rules="required"
        >
          <HeroInputText
            id="other-platform"
            v-model="form.otherPlatform"
            label="Other Platform"
            required
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="prompt"
          rules="required"
        >
          <HeroTextarea
            id="prompt"
            v-model="form.prompt"
            label="Prompt"
            required
            rows="5"
            max-rows="10"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
        <HeroTextarea
          id="expected-result"
          v-model="form.expectedResult"
          label="Expected Result"
          rows="5"
          max-rows="10"
        />

        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="submit"
              variant="primary"
            >
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('prompt-form-modal')"
            >
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>

import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroVueSelectMultiple,
    HeroVueSelect,
    HeroButtonAction,
    HeroTextarea,
    HeroInputText,
  },
  props: {
    promptId: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
    teamName: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
    platform: {
      type: Object,
      default: () => ({
        options: [],
        isLoading: false,
      }),
    },
  },
  data() {
    return {
      showOverlay: false,
      form: {
        name: '',
        teamName: '',
        productIds: [],
        platform: '',
        otherPlatform: '',
        prompt: '',
        expectedResult: '',
      },
    }
  },
  methods: {
    onShown() {
      this.getPromptById()
    },

    async getPromptById() {
      try {
        if (!this.promptId) return

        this.showOverlay = true

        const { data: { data: promptDetail } } = await axiosInstance.get(`prompts/${this.promptId}`)
        this.form = {
          name: promptDetail.name,
          teamName: promptDetail.team_name,
          productIds: promptDetail.product_ids,
          platform: promptDetail.platform_name,
          otherPlatform: promptDetail.other_platform_name || '',
          prompt: promptDetail.prompt,
          expectedResult: promptDetail.expected_result,
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async handleSubmit() {
      try {
        const isFormValid = await this.$refs.observer.validate()

        if (isFormValid) {
          this.showOverlay = true

          const payload = {
            name: this.form.name,
            team_name: this.form.teamName,
            product_ids: this.form.productIds,
            platform_name: this.form.platform,
            other_platform_name: this.form.platform === 'Other' ? this.form.otherPlatform : '',
            prompt: this.form.prompt,
            expected_result: this.form.expectedResult,
          }

          if (this.promptId) {
            await axiosInstance.patch(`prompts/${this.promptId}`, payload)
          } else {
            await axiosInstance.post('prompts', payload)
          }

          this.$emit('success')
          this.$bvModal.hide('prompt-form-modal')
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    resetForm() {
      this.form = {
        name: '',
        productIds: [],
        teamName: '',
        platform: '',
        otherPlatform: '',
        prompt: '',
        expectedResult: '',
      }
    },
  },
}
</script>
