<template>
  <b-modal
    id="confirm-deletion-modal"
    ref="confirm-deletion-modal"
    title="Confirm Deletion"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="getPromptCampaign"
    @hidden="promptCampaigns = []"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <div class="font-weight-bold mb-1">
      Campaigns using this prompt
    </div>
    <b-list-group
      v-if="promptCampaigns.length"
      class="campaign-list"
    >
      <b-list-group-item
        v-for="(promptCampaign, index) in promptCampaigns"
        :key="promptCampaign.name"
        class="d-flex justify-content-between align-items-center"
      >
        <router-link
          :to="`/campaigns/${promptCampaign.campaign_id}`"
          target="_blank"
        >
          {{ promptCampaign.campaign_name }}
        </router-link>
        <b-badge
          :id="`product-count-${index}`"
          variant="primary"
          pill
          class="badge-round"
        >
          {{ promptCampaign.product_count }}
        </b-badge>
        <b-tooltip
          class="w-auto"
          :target="`product-count-${index}`"
          triggers="hover"
        >
          Number of products used in this campaign.
        </b-tooltip>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="text-center">
      This prompt was not used in any campaign.
    </div>
    <hr>
    <b-row>
      <b-col cols="6" offset-sm="6" sm="3">
        <HeroButtonAction
          type="submit"
          variant="primary"
          @click="deletePrompt"
        >
          Yes
        </HeroButtonAction>
      </b-col>
      <b-col cols="6" sm="3">
        <HeroButtonAction
          type="button"
          variant="outline-secondary"
          @click="$bvModal.hide('confirm-deletion-modal')"
        >
          Cancel
        </HeroButtonAction>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroButtonAction,
  },
  props: {
    promptId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      promptCampaigns: [],
    }
  },
  methods: {
    async getPromptCampaign() {
      try {
        if (!this.promptId) return

        this.showOverlay = true

        const { data } = await axiosInstance.get(`prompts/confirm-delete/${this.promptId}`)
        this.promptCampaigns = data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async deletePrompt() {
      try {
        this.showOverlay = true
        const response = await axiosInstance.delete(`prompts/${this.promptId}`)

        await this.$swal({ ...SweetAlert.success, text: response.data.message })

        this.$emit('success')
        this.$bvModal.hide('confirm-deletion-modal')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        if (this.$refs.cardA) {
          this.showOverlay = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.campaign-list {
  max-height: 350px;
  overflow-y: auto;
}
</style>
