<template>
  <b-card-actions
    ref="cardA"
    title="List"
    action-collapse
    action-refresh
    no-body
    @refresh="getPromptList"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
            <div class="search-filter">
              <HeroInputText
                id="filter"
                v-model="tableConfig.filter"
                class="filter-input"
                placeholder="Search"
                @input="debounce(getPromptList, 500)()"
              />
              <HeroButtonAction
                variant="outline-primary"
                type="submit"
                class="btn-action"
                @click="$bvModal.show('prompt-filter-modal')"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="18"
                />
              </HeroButtonAction>
            </div>
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="4" md="3" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="debounce(getPromptList)()"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2">
            <HeroButtonAction
              v-if="$can('Edit', 'Prompts')"
              type="button"
              variant="primary"
              @click="openPromptFormModal()"
            >
              Add
            </HeroButtonAction>
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="onTableSortChanged"
          >
            <template #cell(prompt)="data">
              <HeroTextarea
                id="prompt"
                :value="data.item.prompt"
                rows="3"
                disabled
                readonly
                class="mb-0"
                @copy.prevent
                @click-right="$event.preventDefault()"
              />
            </template>
            <template #cell(products)="{ item: { products } }">
              <ul class="pl-0">
                <li
                  v-for="productDetail in products"
                  :key="productDetail.id"
                >
                  {{ productDetail.name }}
                </li>
              </ul>
            </template>
            <template #cell(platform_name)="{ item: { platform_name, other_platform_name } }">
              {{ platform_name === 'Other' ? other_platform_name : platform_name }}
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
                class="dropdown-action"
              >
                <template #button-content>
                  <div class="more-vertical-icon-wrapper">
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="more-vertical-icon text-body align-middle"
                    />
                  </div>
                </template>
                <b-dropdown-item
                  v-if="$can('Edit', 'Prompts')"
                  v-b-modal="'prompt-form-modal'"
                  @click="openPromptFormModal(data.item.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('Edit', 'Prompts')"
                  @click="openConfirmDeletionModal(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="debounce(getPromptList)()"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>

      <PromptFormModal
        :prompt-id="currentPromptId"
        :product="product"
        :team-name="teamName"
        :platform="platform"
        @success="getPromptList"
      />
      <ConfirmDeletionModal
        :prompt-id="currentPromptId"
        @success="getPromptList"
      />
      <PromptFilterModal
        :product="product"
        :team-name="teamName"
        :platform="platform"
        @search="onSearch"
      />
    </b-card>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import moment from 'moment/moment'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import PromptFormModal from '@/views/prompts/components/PromptFormModal.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import ConfirmDeletionModal from '@/views/prompts/components/ConfirmDeletionModal.vue'
import DebounceMixin from '@/mixins/debounce'
import axiosInstance from '@/libs/axiosInstance'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import PromptFilterModal from '@/views/prompts/components/PromptFilterModal.vue'

export default {
  components: {
    PromptFilterModal,
    HeroButtonAction,
    ConfirmDeletionModal,
    HeroTextarea,
    HeroTablePagination,
    HeroTableStatus,
    HeroInputText,
    HeroTablePerPage,
    BCardActions,
    PromptFormModal,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      items: [],
      currentPromptId: '',
      teamName: {
        options: [],
        isLoading: false,
      },
      product: {
        options: [],
        isLoading: false,
      },
      platform: {
        options: [],
        isLoading: false,
      },
      popoverFilterShow: false,
      tableConfig: {
        filter: '',
        multiFilter: {
          teamName: '',
          products: [],
          platform: '',
        },
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Task',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Team Name',
            key: 'team_name',
          },
          {
            label: 'Products',
            key: 'products',
          },
          {
            label: 'Platform',
            key: 'platform_name',
          },
          {
            label: 'Prompt',
            key: 'prompt',
            thStyle: { width: '30%' },
          },
          {
            label: 'Expected Result',
            key: 'expected_result',
          },
          {
            ...this.$can('Edit', 'Prompts') && {
              label: 'Action',
              key: 'action',
              stickyColumn: false,
            },
          },
        ],
      },
    }
  },
  mounted() {
    this.getProductOptionDropdownList()
    this.getTeamNameList()
    this.getPlatformList()
    this.getPromptList()
  },
  methods: {
    onTableSortChanged(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.getPromptList()
    },

    async getProductOptionDropdownList() {
      try {
        this.product.isLoading = true

        const { data: { data: productOptions } } = await axiosInstance.get('dropdown-options/product-prompt-options')

        this.product.options = productOptions.map(productOption => ({
          text: productOption.name,
          value: productOption.id,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.product.isLoading = false
      }
    },

    async getTeamNameList() {
      try {
        this.teamName.isLoading = true

        const { data: { data: teamNames } } = await axiosInstance.get('prompts/team-names')

        this.teamName.options = teamNames.map(teamName => ({
          text: teamName,
          value: teamName,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.teamName.isLoading = false
      }
    },

    async getPlatformList() {
      try {
        this.platform.isLoading = true

        const { data: { data: platformNames } } = await axiosInstance.get('prompts/platform-names')

        this.platform.options = platformNames.map(platformName => ({
          text: platformName,
          value: platformName,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.platform.isLoading = false
      }
    },

    async getPromptList() {
      try {
        this.$refs.cardA.showLoading = true

        const {
          perPage, currentPage, filter, sortBy, sortDirection, multiFilter: { teamName, products, platform },
        } = this.tableConfig

        const url = `prompts?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}&product_ids=${products}&team_name=${teamName}&platform_name=${platform}`
        const response = await axiosInstance.get(url)

        this.tableConfig.currentPage = +response.data.data.current_page
        this.tableConfig.perPage = +response.data.data.per_page
        this.tableConfig.totalRows = +response.data.data.total
        this.items = response.data.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.cardA) {
          this.$refs.cardA.showLoading = false
        }
      }
    },

    onSearch(filter) {
      this.tableConfig.multiFilter = { ...filter }
      this.getPromptList()
    },

    openPromptFormModal(id) {
      this.currentPromptId = id || ''
      this.$bvModal.show('prompt-form-modal')
    },

    openConfirmDeletionModal(id) {
      this.currentPromptId = id || ''
      this.$bvModal.show('confirm-deletion-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-action {
  border: 1px solid #82868b !important;
}
.btn-action {
  width: 40px;
  height: 38px;
  padding: 8px;
}

.search-filter {
  display: flex;
  column-gap: 8px;
  .filter-input {
    flex: 1 0 0%;
  }
}
</style>
